var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        staticClass: "mb-2",
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    label: _vm.$t("applications.move-in-date"),
                    required: "",
                    icon: "calendar_today",
                    placeholder: "mm/dd/yyyy",
                    mask: "##/##/####",
                    name: "movein_date",
                    value: _vm.meta.move_in_date,
                    rules: [
                      function(v) {
                        return (
                          !!v || _vm.$t("applications.move-in-date-required")
                        )
                      },
                      function(v) {
                        return (
                          /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                            v
                          ) || _vm.$t("applications.move-in-date-invalid")
                        )
                      }
                    ]
                  },
                  on: {
                    change: function($event) {
                      return _vm.inputUpdate("move_in_date", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _vm.type != "current"
                  ? _c("form-text-field", {
                      attrs: {
                        label: _vm.$t("applications.move-out-date"),
                        required: "",
                        icon: "calendar_today",
                        placeholder: "mm/dd/yyyy",
                        mask: "##/##/####",
                        name: "moveout_date",
                        value: _vm.meta.move_out_date,
                        rules: [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t("applications.move-out-date-required")
                            )
                          },
                          function(v) {
                            return (
                              /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                              ) || _vm.$t("applications.move-out-date-invalid")
                            )
                          }
                        ]
                      },
                      on: {
                        change: function($event) {
                          return _vm.inputUpdate("move-out-date", $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-currency-text-field", {
                  attrs: {
                    label: _vm.meta.payment_label,
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          !!v || _vm.$t("applications.payment-amount-required")
                        )
                      }
                    ],
                    value: _vm.meta.rent
                  },
                  on: {
                    input: function($event) {
                      return _vm.inputUpdate("rent", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c(
                  "form-element",
                  {
                    attrs: {
                      label: _vm.$t("applications.reason-for-moving"),
                      required: ""
                    }
                  },
                  [
                    _c("v-textarea", {
                      staticClass: "mt-2",
                      attrs: {
                        solo: "",
                        "auto-grow": "",
                        rows: "3",
                        rules: [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t("applications.reason-for-moving-required")
                            )
                          }
                        ],
                        value: _vm.meta.reason_for_moving,
                        required: "",
                        placeholder: _vm.$t(
                          "applications.enter-reason-for-moving"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.inputUpdate("reason_for_moving", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }