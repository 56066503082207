<template>
    <div>
        <form-element-group class="mb-2">
            <template v-slot:col-1>
                <form-text-field
                    :label="$t('applications.move-in-date')"
                    required
                    icon="calendar_today"
                    placeholder="mm/dd/yyyy"
                    mask="##/##/####"
                    name="movein_date"
                    :value="meta.move_in_date"
                    :rules="[
                        (v) => !!v || $t('applications.move-in-date-required'),
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || $t('applications.move-in-date-invalid'),
                    ]"
                    @change="inputUpdate('move_in_date', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    v-if="type != 'current'"
                    :label="$t('applications.move-out-date')"
                    required
                    icon="calendar_today"
                    placeholder="mm/dd/yyyy"
                    mask="##/##/####"
                    name="moveout_date"
                    :value="meta.move_out_date"
                    :rules="[
                        (v) => !!v || $t('applications.move-out-date-required'),
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || $t('applications.move-out-date-invalid'),
                    ]"
                    @change="inputUpdate('move-out-date', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-currency-text-field
                    :label="meta.payment_label"
                    required
                    :rules="[
                        (v) =>
                            !!v || $t('applications.payment-amount-required'),
                    ]"
                    :value="meta.rent"
                    @input="inputUpdate('rent', $event)"
                ></form-currency-text-field>
            </template>
            <template v-slot:col-2>
                <form-element
                    :label="$t('applications.reason-for-moving')"
                    required
                >
                    <v-textarea
                        class="mt-2"
                        solo
                        auto-grow
                        rows="3"
                        :rules="[
                            (v) =>
                                !!v ||
                                $t('applications.reason-for-moving-required'),
                        ]"
                        :value="meta.reason_for_moving"
                        required
                        :placeholder="
                            $t('applications.enter-reason-for-moving')
                        "
                        @change="inputUpdate('reason_for_moving', $event)"
                    ></v-textarea>
                </form-element>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "rental-meta",
    components: {},
    props: {
        metaData: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "current",
        },
    },
    data() {
        return {
            meta: this.metaData,
        };
    },
    watch: {
        metaData: {
            immediate: true,
            handler(metaData) {
                this.meta = metaData;
            },
        },
    },
    methods: {
        inputUpdate(key, data) {
            this.meta[key] = data;
            this.$emit("update", this.meta);
        },
    },
};
</script>

<style scoped>
</style>